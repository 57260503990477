<template>
  <v-form>
    <v-text-field
      type="number"
      v-model="amount"
      :label="$t('amount')"
      min="0"
      filled
      persistent-hint
      :hint="$t('addToGiftCardAmountInfo')"
    />
  </v-form>
</template>

<script>
export default {
  name: "AddAmountToGiftCard",
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      amount: 0
    }
  },
  watch: {
    amount(value) {
      this.$emit('input', { uuid: this.value.uuid, amount: value }) // this value will have to change when updating to vue 3
      this.$emit('valid', true)
    }
  }
}
</script>

<style scoped>

</style>